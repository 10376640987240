import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #275084;
    color: #ebebeb
  }

  main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88vh;
    flex-direction: column;
  }

  h1 {
    margin: 0;
    padding-top: 24px;
    padding-bottom: 8px;
    font-weight: normal;
    font-size: 78px;
  }

  h2 {
    margin: 0;
    font-weight: normal;
    font-size: 26px;
  }

  h3 {
    margin: 0;
    font-weight: normal;
    font-size: 22px;
  }
`

class App extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      temperature: null,
      date: null,
    }
    this.timer = null;
  }

  componentDidMount() {
    this.fetchData();
    this.timer = setInterval(this.fetchData, 10 * 1000);
  }

  componentWillUnmount() {
    this.timer = null;
  }

  fetchData = async () => {
    try {
      const response = await fetch('/temperature', { method: 'GET' });
      const data = await response.json();
      if(response.status === 200) {
        this.setState(data);
      }
    } catch(err) {
      console.error(err)
    }
  };

  formatDate = () => {
    const unix = this.state.date;
    if (unix === null) return null;
    const date = new Date(Number.parseInt(unix));
    const year = (date.getFullYear()).toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = (date.getDate()).toString().padStart(2, '0');
    const hour = (date.getHours()).toString().padStart(2, '0');
    const minutes = (date.getMinutes()).toString().padStart(2, '0');
    const seconds = (date.getSeconds()).toString().padStart(2, '0');
    return `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`;
  }

  formatTemperature = () => {
    const temperature = this.state.temperature;
    if (temperature === null) {
      return '--,--°C'
    }
    return Number.parseFloat(temperature).toFixed(2).replace('.', ',') + '°C';
  }

  render() {
    return (
      <main>
        <GlobalStyle />
        <h3>
          Temperatura
        </h3>
        <h1>
          {this.formatTemperature()}
        </h1>
        <h2>
          {this.formatDate()}
        </h2>
      </main>
    );
  }
}

export default App;
